var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',_vm._b({attrs:{"handle":".handle-group"},model:{value:(_vm.attributeTemplate),callback:function ($$v) {_vm.attributeTemplate=$$v},expression:"attributeTemplate"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.attributeTemplate),function(attribute){return _c('div',{key:attribute.id},[_c('div',{staticClass:"d-flex align-center"},[_c('icon-drag',{staticClass:"mr-4 handle handle-group"}),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Tên Section")]),_c('tp-text-field',{attrs:{"placeholder":"Nhập tên section","validate":"required"},model:{value:(attribute.group_name),callback:function ($$v) {_vm.$set(attribute, "group_name", $$v)},expression:"attribute.group_name"}})],1),_c('v-tooltip',{attrs:{"bottom":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 red lighten-5",attrs:{"color":"red","icon":"","outlined":""},on:{"click":function($event){return _vm.handleRemoveGroup(attribute.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa section")])])],1),_c('draggable',_vm._b({attrs:{"handle":".handle-attribute","group":{ name: 'attribute' }},model:{value:(attribute.items),callback:function ($$v) {_vm.$set(attribute, "items", $$v)},expression:"attribute.items"}},'draggable',_vm.dragOptions,false),_vm._l((attribute.items),function(item){return _c('div',{key:item.id,staticClass:"mb-3 d-flex"},[_c('icon-drag',{staticClass:"ml-8 mr-4 handle handle-attribute"}),_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Tên trường")]),_c('tp-text-field',{attrs:{"placeholder":"Nhập tên trường","validate":"required"},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('div',{staticClass:"ml-3 flex-1"},[_vm._l((item.values),function(value,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-1"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Giá trị")]),_c('specs-input',{staticClass:"w-full",model:{value:(value.value),callback:function ($$v) {_vm.$set(value, "value", $$v)},expression:"value.value"}})],1),_c('div',{staticClass:"ml-3 flex-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Bài viết")]),_c('autocomplete-article',{attrs:{"article":value.article,"sites":_vm.modelSiteConfigs},model:{value:(value.article_id),callback:function ($$v) {_vm.$set(value, "article_id", $$v)},expression:"value.article_id"}})],1),_c('v-tooltip',{attrs:{"bottom":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 red lighten-5 mt-6",attrs:{"color":"red","icon":"","outlined":""},on:{"click":function($event){return _vm.handleRemoveValue(attribute.id, item.id, value.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa giá trị")])])],1),(
                  value && value.detailValues && value.detailValues.length
                )?_c('div',{staticClass:"d-flex flex-1 flex-col"},_vm._l((value.detailValues),function(detailValue){return _c('div',{key:detailValue.id,staticClass:"d-flex flex-1 ml-8"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Giá trị chi tiết")]),_c('tp-text-field',{attrs:{"placeholder":"Nhập giá trị cụ thể","validate":"required"},model:{value:(detailValue.value),callback:function ($$v) {_vm.$set(detailValue, "value", $$v)},expression:"detailValue.value"}})],1),_c('div',{staticClass:"flex-1 ml-3"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Bài viết chi tiết")]),_c('autocomplete-article',{attrs:{"article":detailValue.article,"sites":_vm.modelSiteConfigs},model:{value:(detailValue.article_id),callback:function ($$v) {_vm.$set(detailValue, "article_id", $$v)},expression:"detailValue.article_id"}})],1),_c('v-tooltip',{attrs:{"bottom":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 red lighten-5 mt-6",attrs:{"color":"red","icon":"","outlined":""},on:{"click":function($event){return _vm.handleRemoveValueDetail(
                            attribute.id,
                            item.id,
                            value.id,
                            detailValue.id
                          )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa giá trị chi tiết "+_vm._s(index))])])],1)}),0):_vm._e(),_c('v-tooltip',{attrs:{"right":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary lighten-5 mb-4 ml-8",attrs:{"color":"primary","icon":"","outlined":""},on:{"click":function($event){return _vm.handleAddValueDetail(attribute.id, item.id, value.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Thêm giá trị chi tiết")])])],1)}),_c('v-tooltip',{attrs:{"right":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary lighten-5 mb-4",attrs:{"color":"primary","icon":"","outlined":""},on:{"click":function($event){return _vm.handleAddValue(attribute.id, item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Thêm giá trị")])])],2),_c('v-tooltip',{attrs:{"bottom":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 red lighten-5 mt-6",attrs:{"color":"red","icon":"","outlined":""},on:{"click":function($event){return _vm.handleRemoveAttribute(attribute.id, item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa thuộc tính")])])],1)}),0),_c('v-tooltip',{attrs:{"right":"","content-class":"elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary lighten-5",attrs:{"color":"primary","icon":"","outlined":""},on:{"click":function($event){return _vm.handleAddAttribute(attribute.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Thêm thuộc tính")])]),_c('v-divider',{staticClass:"mt-3 mb-6"})],1)}),0),_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""},on:{"click":_vm.handleAddGroup}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm section")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }