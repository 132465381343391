<template>
  <v-card class="order-sidebar" flat>
    <div
      :style="heightStyle"
      class="sidebar-content px-4 d-flex flex-col justify-start items-stretch text-body-1"
    >
      <div class="mt-6 d-flex flex-col justify-start items-stretch">
        <div>
          <div class="mb-2">
            <div class="font-weight-bold">Mã khuyến mại</div>
          </div>

          <tp-text-field
            v-model="saleTabActive.promo_code"
            placeholder="Nhập mã khuyến mại"
            hide-details
          ></tp-text-field>
        </div>

        <v-divider class="my-3"></v-divider>

        <summary-amount class="mt-1"></summary-amount>

        <v-divider class="my-3"></v-divider>

        <div>
          <div class="d-flex align-center justify-space-between">
            <div class="font-weight-bold">Khách hàng</div>

            <button-choose-customer
              v-model="saleTabActive.customer"
              :button-name="
                saleTabActive.customer ? 'Thay đổi' : 'Chọn khách hàng'
              "
              :is-disabled="saleTabActive.status > 2"
            ></button-choose-customer>
          </div>

          <button-view-customer-info
            v-if="saleTabActive.customer"
            class="mt-1"
          ></button-view-customer-info>
        </div>

        <v-divider class="my-3"></v-divider>

        <div>
          <div class="d-flex align-center justify-space-between">
            <div class="font-weight-bold">Giao hàng</div>

            <button-setting-delivery
              v-model="saleTabActive"
              :button-name="
                addressDetails.length ? 'Thay đổi' : 'Phương thức giao hàng'
              "
              :is-disabled="saleTabActive.status > 2"
            ></button-setting-delivery>
          </div>

          <div class="mt-1">{{ addressDetails }}</div>
        </div>

        <div
          v-if="!!addressDetails"
          class="mt-2 d-flex align-center justify-space-between"
        >
          <div class="font-weight-bold">Hẹn giao</div>

          <button-choose-delivery-date
            v-model="saleTabActive.shipping_est_date"
          ></button-choose-delivery-date>
        </div>

        <v-divider class="my-3"></v-divider>

        <div class="mt-1">
          <panel-responsibility></panel-responsibility>
        </div>

        <v-divider class="my-3"></v-divider>

        <div>
          <div class="mb-2">
            <div class="font-weight-bold">Ghi chú của khách</div>
          </div>

          <v-textarea
            v-model="saleTabActive.note"
            :readonly="saleTabActive.cancel_status === 1"
            class="text-body-1"
            outlined
            placeholder="Nhập ghi chú"
            rows="3"
          ></v-textarea>
        </div>

        <button-send-telegram
          :id="saleTabActive.id"
          :note="saleTabActive.note"
        ></button-send-telegram>

        <div class="mt-4">
          <div class="mb-2">
            <div class="font-weight-bold">Ghi chú nội bộ</div>
          </div>

          <v-textarea
            v-model="saleTabActive.internal_note"
            :readonly="saleTabActive.cancel_status === 1"
            class="text-body-1"
            outlined
            placeholder="Nhập ghi chú"
            rows="3"
          ></v-textarea>
        </div>
      </div>
    </div>

    <v-card-actions
      v-if="!saleTabActive.cancel_status"
      class="card-action d-flex flex-row justify-space-between items-center px-6"
    >
      <sidebar-bottom
        :order-form-ref="orderFormRef"
        class="sidebar-bottom"
      ></sidebar-bottom>
    </v-card-actions>
  </v-card>
</template>

<script>
import SidebarBottom from "@/modules/Sale/pages/Order/pages/OrderPage/components/TabDetailOrder/components/OrderSidebar/SidebarBottom";
import SummaryAmount from "@/modules/Sale/pages/Order/pages/OrderPage/components/TabDetailOrder/components/OrderSidebar/SummaryAmount";
import PanelResponsibility from "../PanelResponsibility.vue";
import ButtonChooseCustomer from "./ButtonChooseCustomer.vue";
import ButtonChooseDeliveryDate from "./ButtonChooseDeliveryDate.vue";
import ButtonSettingDelivery from "./ButtonSettingDelivery.vue";
import ButtonViewCustomerInfo from "./ButtonViewCustomerInfo.vue";
import ButtonSendTelegram from "@/modules/Sale/pages/Order/pages/OrderPage/components/TabDetailOrder/components/OrderSidebar/button-send-telegram.vue";

export default {
  name: "SideBar",

  components: {
    ButtonSendTelegram,
    ButtonChooseDeliveryDate,
    ButtonChooseCustomer,
    ButtonViewCustomerInfo,
    SummaryAmount,
    SidebarBottom,
    PanelResponsibility,
    ButtonSettingDelivery
  },

  props: {
    orderFormRef: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {
      panel: [0, 1, 2]
    };
  },

  computed: {
    heightStyle() {
      if (this.saleTabActive.id && this.saleTabActive.status > 5) {
        return "height: calc(100vh - 135px)";
      } else if (this.saleTabActive.id) {
        return "height: calc(100vh - 278px)";
      } else {
        return "height: calc(100vh - 180px)";
      }
    },

    addressDetails() {
      const {
        received_address,
        received_method,
        branch_nhan_id
      } = this.saleTabActive;

      if (!received_address) return this.getBranchName(branch_nhan_id);

      const { so_nha, phuong_xa, quan_huyen, tinh_thanhpho } = received_address;

      return received_method === 1
        ? `${so_nha}, ${phuong_xa}, ${quan_huyen}, ${tinh_thanhpho}`
        : `Cửa hàng: ${this.getBranchName(branch_nhan_id)}`;
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    saleTabActive: {
      get() {
        return this.$store.getters["SALE/saleTabActive"];
      },
      set(value) {
        this.$store.dispatch("SALE/setSaleTabActive", value);
      }
    }
  },

  methods: {
    getBranchName(branchId) {
      const branch = this.branches.find(branch => branch.id === branchId);
      return branch ? branch.name : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.order-sidebar {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .sidebar-content {
    overflow: auto;
    flex: 1 1 0%;
  }
}

.sidebar-bottom {
  background-color: white;
  width: 356px;
  z-index: 1;
}
</style>
