<template>
  <v-card class=" mx-5" flat>
    <v-overlay
      :absolute="true"
      :opacity="0.6"
      :value="
        productTypeStatusRequest.value ===
          'loading-getAttributeTemplateByCategoryId'
      "
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div class="pa-4 d-flex justify-space-between">
      <div class="font-weight-bold text-h6">
        Template cấu hình: {{ productTypeName }}
      </div>

      <div>
        <v-btn
          class="mr-3"
          color="primary"
          depressed
          outlined
          @click="handleGoBack"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Quay lại</v-btn
        >

        <v-btn
          :loading="statusRequest.value === 'loading-updateAttributeTemplate'"
          color="primary"
          depressed
          @click="handleSave"
        >
          <v-icon left>mdi-content-save</v-icon>
          Lưu</v-btn
        >
      </div>
    </div>

    <v-divider></v-divider>

    <v-form class="form-wrapper" ref="attributeFormRef">
      <form-item-attribute></form-item-attribute>
    </v-form>
  </v-card>
</template>

<script>
import FormItemAttribute from "./components/FormItemAttribute.vue";
import { showToastActionAlert } from "@/core/composables";

export default {
  components: {
    FormItemAttribute
  },

  computed: {
    productTypeStatusRequest() {
      return this.$store.getters["PRODUCT_TYPE/statusRequest"];
    },

    attributeTemplate() {
      return this.$store.getters["PRODUCT_TYPE/attributeTemplate"];
    },

    statusRequest() {
      return this.$store.getters["PRODUCT_TYPE/statusRequest"];
    }
  },

  data() {
    return {
      productTypeId: this.$route.params.productTypeId,
      productTypeName: this.$route.query.name
    };
  },

  created() {
    this.$store.dispatch(
      "PRODUCT_TYPE/getAttributeTemplateByCategoryId",
      this.productTypeId
    );

    this.$store.dispatch(
      "PRODUCT_TYPE/getAttributesByCategoryId",
      this.productTypeId
    );
  },

  methods: {
    handleGoBack() {
      this.$router.push({ name: "goods_product-types" });
    },

    validateFormData() {
      const isValid = this.$refs.attributeFormRef.validate();
      if (!isValid) {
        this.$nextTick(() => {
          const el = document.querySelector(
            ".v-messages__message:first-of-type"
          );
          el.scrollIntoView({ behavior: "smooth" });
        });
      }

      return isValid;
    },

    async handleSave() {
      const isValid = this.validateFormData.call(this);

      if (!isValid) return;

      await this.$store.dispatch("PRODUCT_TYPE/updateAttributeTemplate", {
        categoryId: this.productTypeId,
        payload: this.attributeTemplate
      });

      showToastActionAlert(this, {
        message: `Cập nhật template cho ${this.productTypeName} thành công`
      });

      this.isVisible = false;
    }
  }
};
</script>

<style scoped>
.form-wrapper {
  height: calc(100vh - 212px);
  padding: 20px;
  overflow: auto;
}
</style>
