<template>
  <v-data-table
    v-model="selected"
    :headers="isViewedCreatedPrice ? headers : saleHeaders"
    :items="productOptions"
    :loading="productOptionStatusRequest.value === 'loading-getProductOptions'"
    :options="dataTableOptions"
    calculate-widths
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    disable-pagination
    hide-default-footer
    item-key="id"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    show-select
    @update:options="updateSort($event)"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.SKU`]="{ item }">
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="copied-label font-weight-bold"
              v-bind="attrs"
              v-on="on"
              @click.stop="copyToClipboard(item.SKU)"
            >
              {{ item.SKU }}
            </div>
          </template>

          <span>Sao chép</span>
        </v-tooltip>
      </div>

      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="copied-label"
              v-bind="attrs"
              v-on="on"
              @click.stop="copyToClipboard(item.MPN)"
            >
              {{ item.MPN ? item.MPN : "N/A" }}
            </div>
          </template>

          <span>Sao chép</span>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.product_name`]="{ item }">
      <div class="my-2">
        <div class="font-weight-bold">
          {{ item.product_name }}
        </div>
        <div
          class="text-subtitle-2 font-weight-light grey--text text--darken-2 tag-p__mb-0"
          v-html="item.specs"
        ></div>
        <div
          v-if="item.promotion_gifts"
          v-html="item.promotion_gifts"
          class="text-subtitle-2 font-weight-light red--text text--accent-2"
        ></div>
      </div>
    </template>

    <template v-slot:[`item.date_ton_lau`]="{ item }">
      {{ item.date_ton_lau === "" ? 0 : item.date_ton_lau }}
    </template>

    <template v-slot:[`item.ready_to_sell_qty`]="{ item }">
      <v-chip
        class="font-weight-bold rounded px-2"
        color="green lighten-4"
        label
        small
        @click.stop="openModalSerialPicker(item)"
      >
        {{ item.ready_to_sell_qty }}
      </v-chip>
    </template>

    <template v-slot:[`item.ordered_qty`]="{ item }">
      <v-chip
        class="font-weight-bold rounded px-2"
        color="red lighten-4"
        label
        small
        @click.stop="openModalSerialPicker(item)"
      >
        {{ item.ordered_qty }}
      </v-chip>
    </template>

    <template v-slot:[`item.waiting_ordered_qty`]="{ item }">
      <v-chip
        class="font-weight-bold rounded px-2"
        color="blue lighten-4"
        label
        small
        @click.stop="openModalSerialPicker(item)"
      >
        {{ item.waiting_ordered_qty }}
      </v-chip>
    </template>

    <template v-slot:[`item.exhibited_qty`]="{ item }">
      <v-chip
        class="font-weight-bold rounded px-2"
        color="deep-purple lighten-4"
        label
        small
        @click.stop="openModalSerialPicker(item)"
      >
        {{ item.exhibited_qty }}
      </v-chip>
    </template>

    <template v-slot:[`item.so_luong_dang_ve`]="{ item }">
      <v-chip
        class="font-weight-bold rounded px-2"
        color="orange lighten-4"
        label
        small
        @click.stop="openModalSerialPicker(item)"
      >
        {{ item.so_luong_dang_ve }}
      </v-chip>
    </template>

    <template v-slot:[`item.gia_von_trung_binh`]="{ item }">
      <div>
        {{ item.gia_von_trung_binh | formatCurrency }}
      </div>
    </template>

    <template v-slot:[`item.price`]="{ item }">
      <div>
        <template
          v-if="calculateSalePrice(item.price, item.promotions) !== item.price"
        >
          <div>
            {{
              calculateSalePrice(item.price, item.promotions) | formatCurrency
            }}
          </div>
          <div
            class="text-body-2 grey--text text--darken-2 text-decoration-line-through"
          >
            {{ item.price | formatCurrency }}
          </div>
        </template>
        <div v-else>
          {{ item.price | formatCurrency }}
        </div>
      </div>
    </template>

    <template v-slot:[`item.sale_price`]="{ item }">
      <div v-if="item.sale_price">
        {{ item.sale_price | formatCurrency }}

        <div
          v-if="!(isSalePermission && isMarketingPermission)"
          :class="getProductMargin(item) >= 0 ? 'green--text' : 'red--text'"
          class="font-weight-medium text-subtitle-2"
        >
          <div v-if="item.gia_von_trung_binh">
            {{ getProductMargin(item) }}%
          </div>

          <div v-else>
            N/A
          </div>
        </div>
      </div>

      <div v-else>
        N/A
      </div>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <button-view-sku-in-website
        v-if="item.urls"
        :urls="item.urls"
      ></button-view-sku-in-website>
    </template>
  </v-data-table>
</template>

<script>
import {
  FULL_HEADERS_PRODUCT_OPTION_TABLE,
  SALE_HEADERS_PRODUCT_OPTION_TABLE
} from "@/modules/Goods/constants";

import ButtonViewSkuInWebsite from "./ButtonViewSkuInWebsite.vue";

export default {
  components: {
    ButtonViewSkuInWebsite
  },

  props: {
    selectedProductOptions: {
      type: Array,
      required: true
    },
    sort: {
      type: Object
    }
  },

  data() {
    return {
      headers: FULL_HEADERS_PRODUCT_OPTION_TABLE,
      saleHeaders: SALE_HEADERS_PRODUCT_OPTION_TABLE
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    dataTableOptions() {
      if (this.sort && this.sort.field) {
        return {
          sortBy: [this.sort.field],
          sortDesc: [this.sort.type === 2]
        };
      } else return null;
    },

    productOptions() {
      return this.$store.getters["PRODUCT_OPTION/productOptions"];
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },

    selected: {
      get() {
        return this.selectedProductOptions;
      },
      set(val) {
        this.$emit("updateSelectedProductOptions", val);
      }
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  created() {
    if (this.branches && this.branches.length === 0)
      this.$store.dispatch("BRANCH/getAllBranches");
  },

  methods: {
    getProductMargin(item) {
      return (
        Math.round(
          ((item.sale_price - item.gia_von_trung_binh) / item.sale_price) *
            100 *
            10
        ) / 10
      );
    },
    calculateSalePrice(price, promotions) {
      if (!promotions || (promotions && !promotions.promo_type_12))
        return price;

      const promotion = promotions.promo_type_12;

      if (promotion.tk_type === 1) {
        return (parseInt(price) * (100 - parseInt(promotion.tk_percent))) / 100;
      }
      if (promotion.tk_type === 2) {
        return parseInt(price) - parseInt(promotion.tk_money);
      }
    },

    calculateProfitMargin(item) {
      const price = this.calculateSalePrice(item.price, item.promotions);

      if (item.sale_price === 0) return 0;

      return Math.floor((price / item.gia_von_trung_binh) * 100) - 100;
    },

    getLaunchStatus(val) {
      if (val === 1) return null;
      else if (val === 2) return "orange lighten-4";
      else return "cyan lighten-4";
    },

    async openModalProduct(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        product.id
      );

      this.$modal.show({
        name: "modal-product"
      });
    },

    async openModalSerialPicker(item) {
      await this.$store.dispatch("MODAL_SERIAL_PICKER/setProductOption", {
        MPN: item.MPN,
        SKU: item.SKU,
        id: item.id,
        name: item.name,
        product_name: item.product_name
      });

      // Get branch serials list of product option
      await this.$store.dispatch(
        "MODAL_SERIAL_PICKER/getBranchSerialsList",
        item.id
      );
      await this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setFilteredBranchSerialsList",
        []
      );

      // Reset picked serials state
      await this.$store.dispatch("MODAL_SERIAL_PICKER/setPickedSerials", []);
      // Open modal
      this.$modal.show({
        name: "modal-show-serial"
      });
    },

    updateSort(val) {
      if (val.sortBy) {
        this.$emit("updateSort", {
          type: val.sortDesc.length > 0 && val.sortDesc[0] ? 2 : 1,
          field: val.sortBy[0]
        });
      }
    },

    async viewDetail(item) {
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionInfo",
        item.id
      );
      await this.$store.dispatch("PRODUCT_OPTION/getProductOptionSerials", {
        id: item.id
      });
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionTransactionHistory",
        {
          id: item.id
        }
      );
      this.$modal.show({
        name: "modal-product-options-detail"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }

  .copied-label {
    cursor: pointer;
    display: inline-block;
  }

  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
