<template>
  <div>
    <draggable
      v-model="attributeTemplate"
      v-bind="dragOptions"
      handle=".handle-group"
    >
      <div v-for="attribute in attributeTemplate" :key="attribute.id">
        <div class="d-flex align-center">
          <icon-drag class="mr-4 handle handle-group"></icon-drag>

          <div class="flex-1">
            <div class="font-weight-bold">Tên Section</div>

            <tp-text-field
              v-model="attribute.group_name"
              placeholder="Nhập tên section"
              validate="required"
            ></tp-text-field>
          </div>

          <v-tooltip bottom content-class="elevation-4 px-2" nudge-top="5px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-3 red lighten-5"
                color="red"
                icon
                outlined
                v-bind="attrs"
                v-on="on"
                @click="handleRemoveGroup(attribute.id)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>

            <span>Xóa section</span>
          </v-tooltip>
        </div>

        <draggable
          v-model="attribute.items"
          v-bind="dragOptions"
          handle=".handle-attribute"
          :group="{ name: 'attribute' }"
        >
          <div
            v-for="item in attribute.items"
            :key="item.id"
            class="mb-3 d-flex"
          >
            <icon-drag class="ml-8 mr-4 handle handle-attribute"></icon-drag>

            <div>
              <div class="font-weight-bold">Tên trường</div>

              <tp-text-field
                v-model="item.label"
                placeholder="Nhập tên trường"
                validate="required"
              ></tp-text-field>
            </div>

            <div class="ml-3 flex-1">
              <div v-for="(value, index) in item.values" :key="index" class="">
                <div class="d-flex flex-1">
                  <div class="flex-1">
                    <div class="font-weight-bold">Giá trị</div>

                    <specs-input
                      v-model="value.value"
                      class="w-full"
                    ></specs-input>
                  </div>

                  <div class="ml-3 flex-1">
                    <div class="font-weight-bold">Bài viết</div>

                    <autocomplete-article
                      v-model="value.article_id"
                      :article="value.article"
                      :sites="modelSiteConfigs"
                    ></autocomplete-article>
                  </div>

                  <v-tooltip
                    bottom
                    content-class="elevation-4 px-2"
                    nudge-top="5px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-3 red lighten-5 mt-6"
                        color="red"
                        icon
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          handleRemoveValue(attribute.id, item.id, value.id)
                        "
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>

                    <span>Xóa giá trị</span>
                  </v-tooltip>
                </div>

                <div
                  v-if="
                    value && value.detailValues && value.detailValues.length
                  "
                  class="d-flex flex-1 flex-col"
                >
                  <div
                    v-for="detailValue in value.detailValues"
                    :key="detailValue.id"
                    class="d-flex flex-1 ml-8"
                  >
                    <div class="flex-1">
                      <div class="font-weight-bold">Giá trị chi tiết</div>

                      <tp-text-field
                        v-model="detailValue.value"
                        placeholder="Nhập giá trị cụ thể"
                        validate="required"
                      ></tp-text-field>
                    </div>

                    <div class="flex-1 ml-3">
                      <div class="font-weight-bold">Bài viết chi tiết</div>

                      <autocomplete-article
                        v-model="detailValue.article_id"
                        :article="detailValue.article"
                        :sites="modelSiteConfigs"
                      ></autocomplete-article>
                    </div>

                    <v-tooltip
                      bottom
                      content-class="elevation-4 px-2"
                      nudge-top="5px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ml-3 red lighten-5 mt-6"
                          color="red"
                          icon
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            handleRemoveValueDetail(
                              attribute.id,
                              item.id,
                              value.id,
                              detailValue.id
                            )
                          "
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </template>

                      <span>Xóa giá trị chi tiết {{ index }}</span>
                    </v-tooltip>
                  </div>
                </div>

                <v-tooltip
                  right
                  content-class="elevation-4 px-2"
                  nudge-top="5px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="primary lighten-5 mb-4 ml-8"
                      color="primary"
                      icon
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        handleAddValueDetail(attribute.id, item.id, value.id)
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <span>Thêm giá trị chi tiết</span>
                </v-tooltip>
              </div>

              <v-tooltip right content-class="elevation-4 px-2" nudge-top="5px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="primary lighten-5 mb-4"
                    color="primary"
                    icon
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddValue(attribute.id, item.id)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <span>Thêm giá trị</span>
              </v-tooltip>
            </div>

            <v-tooltip bottom content-class="elevation-4 px-2" nudge-top="5px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3 red lighten-5 mt-6"
                  color="red"
                  icon
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="handleRemoveAttribute(attribute.id, item.id)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </template>

              <span>Xóa thuộc tính</span>
            </v-tooltip>
          </div>
        </draggable>

        <v-tooltip right content-class="elevation-4 px-2" nudge-top="5px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="primary lighten-5"
              color="primary"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              @click="handleAddAttribute(attribute.id)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <span>Thêm thuộc tính</span>
        </v-tooltip>

        <v-divider class="mt-3 mb-6"></v-divider>
      </div>
    </draggable>

    <v-btn color="primary" depressed outlined @click="handleAddGroup">
      <v-icon left>mdi-plus</v-icon>
      Thêm section</v-btn
    >
  </div>
</template>

<script>
import draggable from "vuedraggable";
import "floating-vue/dist/style.css";
import IconDrag from "@/core/assets/icons/IconDrag.vue";
import SpecsInput from "./SpecsInput.vue";
import AutocompleteArticle from "./AutocompleteArticle.vue";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  components: {
    draggable,
    IconDrag,
    SpecsInput,
    AutocompleteArticle
  },

  data() {
    return {
      productTypeId: this.$route.params.productTypeId,
      LIST_MODEL_TYPES: LIST_MODEL_TYPES,
      modelSiteConfigs: []
    };
  },

  computed: {
    attributeTemplate: {
      get() {
        return this.$store.getters["PRODUCT_TYPE/attributeTemplate"];
      },
      set(value) {
        this.$store.commit("PRODUCT_TYPE/SET_ATTRIBUTE_TEMPLATE", value);
      }
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  created() {
    this.getModelSiteConfigs();
  },

  methods: {
    async getModelSiteConfigs() {
      const response = await this.$store.dispatch(
        "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        {
          modelId: this.productTypeId,
          modelType: LIST_MODEL_TYPES.PRODUCT_TYPE
        }
      );

      this.modelSiteConfigs = response
        .filter(item => item.available)
        .map(item => item.site);
    },

    handleAddAttribute(groupId) {
      const groupObj = this.attributeTemplate.find(
        group => group.id === groupId
      );

      if (groupObj) {
        groupObj.items.push({
          id: this.getUniqueId(),
          label: "",
          values: [
            {
              id: this.getUniqueId(),
              value: "",
              article_id: null
            }
          ]
        });
      }
    },

    handleAddValue(groupId, itemId) {
      const groupObj = this.attributeTemplate.find(
        group => group.id === groupId
      );

      if (groupObj) {
        const itemObj = groupObj.items.find(item => item.id === itemId);

        if (itemObj) {
          itemObj.values.push({
            id: this.getUniqueId(),
            value: "",
            article_id: null,
            detailValues: []
          });
        }
      }
    },

    handleAddValueDetail(groupId, itemId, valueId) {
      const groupObj = this.attributeTemplate.find(
        group => group.id === groupId
      );

      if (groupObj) {
        const itemObj = groupObj.items.find(item => item.id === itemId);

        const valueObj = itemObj.values.find(value => value.id === valueId);

        if (valueObj && valueObj.detailValues) {
          valueObj.detailValues.push({
            id: this.getUniqueId(),
            value: "",
            article_id: null
          });
        }
      }
    },

    handleRemoveValueDetail(groupId, itemId, valueId, detailValueId) {
      const groupObj = this.attributeTemplate.find(
        group => group.id === groupId
      );

      if (groupObj) {
        const itemObj = groupObj.items.find(item => item.id === itemId);

        if (itemObj) {
          const valueObj = itemObj.values.find(value => value.id === valueId);

          if (valueObj) {
            valueObj.detailValues = valueObj.detailValues.filter(
              detailValue => detailValue.id !== detailValueId
            );
          }
        }
      }
    },

    handleRemoveValue(groupId, itemId, valueId) {
      const groupObj = this.attributeTemplate.find(
        group => group.id === groupId
      );

      if (groupObj) {
        const itemObj = groupObj.items.find(item => item.id === itemId);

        if (itemObj) {
          itemObj.values = itemObj.values.filter(value => value.id !== valueId);
        }
      }
    },

    handleRemoveAttribute(groupId, itemId) {
      const groupObj = this.attributeTemplate.find(
        group => group.id === groupId
      );

      if (groupObj) {
        groupObj.items = groupObj.items.filter(item => item.id !== itemId);
      }
    },

    handleAddGroup() {
      this.attributeTemplate.push({
        id: this.getUniqueId(),
        group_name: "",
        items: []
      });
    },

    handleRemoveGroup(groupId) {
      this.attributeTemplate = this.attributeTemplate.filter(
        group => group.id !== groupId
      );
    },

    getUniqueId() {
      return Math.floor(Math.random() * 1000000) + "-" + Date.now();
    }
  }
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #0065ee;
}

.handle {
  cursor: pointer;
}
</style>
